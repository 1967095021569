import { AApi } from "@/service/AApi";

const API = new AApi({ url: "complain-information", isConvertFormData: true },
  {
    exportExcel: {
      async request(data) {
        return this.sdk({
          url: "/complaint-export",
          responseType: "blob",
          params: data
        });
      },
    },
  }
);
export default API