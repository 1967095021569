<template>
  <PageListLayout
    icon="mdi-alert-octagon"
    title="THÔNG TIN KHIẾU NẠI"
    subTitle="Thông tin khiếu nại"
    useAdd
    titleAdd="Tạo mới Thông tin khiếu nại"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="side-right">
      <div class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :loading="loadingExport"
              class="mr-1"
              icon
              v-on="on"
              @click="exportExcel"
            >
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Kết xuất dữ liệu</span>
        </v-tooltip>
      </div>
    </template>
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.customer_id"
          :items="customersList"
          label="Khách hàng"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content :title="data.item.name">
                <v-list-item-title
                  class="style_auto_complate"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.reciever"
          :items="ListEmployee"
          label="Người tiếp nhận"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <DateRangeComponent
          label="Ngày tiếp nhận"
          v-model="formSearch.date_reception"
        />
      </div>
      <div class="mt-4">
        <DateRangeComponent
          label="Ngày xử lý"
          v-model="formSearch.processing_date"
        />
      </div>
      <div class="mt-4">
        <DateRangeComponent
          label="Ngày hoàn thành"
          v-model="formSearch.finish_date"
        />
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.type"
          :items="listType"
          label="Loại khiếu nại"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.status"
          :items="statusList"
          label="Trạng thái"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span :class="`badge ${item.status && styleStatus[item.status]}`">{{
          item.status && getNameStatus(item.status)
        }}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ handleShowType(item.type) }}
      </template>
      <template v-slot:[`item.reception_channel_type`]="{ item }">
        {{ handleShowChannel(item.reception_channel_type) }}
      </template>
      <template v-slot:[`item.date_reception`]="{ item }">
        {{ dateFormat(item.date_reception) }}
      </template>
      <template v-slot:[`item.processing_date`]="{ item }">
        {{ dateFormat(item.processing_date) }}
      </template>
      <template v-slot:[`item.finish_date`]="{ item }">
        {{ dateFormat(item.finish_date) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
  </PageListLayout>
</template>
<script>
// import CreateEdit from "./create-edit";
import { debounce } from "lodash";
import {
  statusList,
  styleStatus,
  channels,
  listType,
} from "@/constants/khieunai";
import API from "@/api/khieunai.api";
import KHACHHANG from "@/api/khachhang";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import { getDanhMucCon } from "@/api/danhmuc.js";
import DateRangeComponent from "@/components/Date/date-range";
import { formatDate } from "@/utils/date";
import { saveAs } from "file-saver";

export default {
  components: { DateRangeComponent },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      data: [],
      customersList: [],
      ListEmployee: [],
      channels,
      listType,
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      styleStatus,
      statusList,
      formSearch: {},
      loadingExport: false,
      headers: [
        {
          text: "STT",
          align: "center",
          sortable: false,
          value: "stt",
        },
        { text: "Người tiếp nhận", value: "nguoi_tiep_nhan.name" },
        { text: "Ngày tiếp nhận", value: "date_reception" },
        { text: "Tên công ty", value: "customer.name" },
        { text: "Loại khiếu nại", value: "type" },
        { text: "Nội dung tiếp nhận", value: "info" },
        { text: "Kênh tiếp nhận", value: "reception_channel_type" },
        { text: "Nguyên nhân sự việc", value: "reason" },
        { text: "Biện pháp xử lý", value: "solution" },
        { text: "Ngày xử lý", value: "processing_date" },
        { text: "Ngày hoàn thành", value: "finish_date" },
        { text: "Trạng thái", value: "status" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {
    tableData() {
      return this.data.map((x, i) => {
        return {
          stt: i + 1,
          ...x,
        };
      });
    },
  },
  created() {
    this.getAllEmployee();
    this.getAllCustomers();
    this.fetchData();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function (val) {
        this.page = 1;
        this.fetchData();
      }, 300),
    },
  },
  methods: {
    handleShowChannel(val) {
      return channels.find((x) => x.id == val)?.name;
    },
    handleShowType(val) {
      return listType.find((x) => x.id == val)?.name;
    },
    // async fetchProductPriceType() {
    //   const res = await getDanhMucCon({ code: "LOAIKHIEUNAI", isActive: true });
    //   this.listType = res;
    // },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    async fetchData(params = {}) {
      try {
        this.loading = true;
        const res = await API.list({
          page: this.page,
          perPage: this.itemsPerPage,
          ...this.formSearch,
        });
        this.data = res.data.data;
        this.pageCount = res.data.last_page;
      } finally {
        this.loading = false;
      }
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.ListEmployee = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllCustomers() {
      const res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    changePage(val) {
      this.page = val;
      this.fetchData();
    },
    async editItem(item) {
      // const res = await show(item.id);
      // this.$refs.form.showFormEdit(item);
      this.$router.push({
        path: `/quanlyyeucaukhieunai/chitietkhieunai/${item.id}`,
      });
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa",
        width: 500,
        body: "Bạn có chắc chắn muốn xoá khiếu nại?",
        action: async () => await API.destroy(item.id),
        onDone: () => this.fetchData(),
        disableMessage: true,
      });
    },
    createItem() {
      // this.$refs.form.showFormAdd();
      this.$router.push({
        path: `/quanlyyeucaukhieunai/chitietkhieunai/themmoi`,
      });
    },
    getNameStatus(id) {
      let find = statusList.find((x) => x.id == id);
      return find ? find.name : "";
    },
    dateFormat(date) {
      return formatDate(date);
    },
    async exportExcel() {
      this.loadingExport = true;
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      let newdate = day + "/" + month + "/" + year;
      const res = await API.exportExcel(this.formSearch);
      if(res && res.isDone){
        saveAs(new Blob([res.data]), "Khieunai" +"-"+ newdate + ".xlsx");
      }
      this.loadingExport = false;
    },
  },
};
</script>
<style scoped>
.style_auto_complate {
  max-width: 200px;
}
</style>